import { React } from "../../../react";
import { Component, Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Hero = lazy(() => import("./AssistComponents/Hero"));
const LogoCloud = lazy(() => import("./AssistComponents/LogoCloud"));
const Feature = lazy(() => import("./AssistComponents/Feature"));
const AlternativeFeature = lazy(() =>
  import("./AssistComponents/AlternativeFeature")
);
const FeatureList = lazy(() => import("./AssistComponents/FeatureList"));
const FAQ = lazy(() => import("./AssistComponents/FAQ"));
const CenteredCard = lazy(() => import("./AssistComponents/CenteredCard"));
const LatestAssistPosts = lazy(() =>
  import("./AssistComponents/LatestAssistPosts")
);
const Consultation = lazy(() =>
  import("./AssistComponents/Consultation")
);
const AssistProVideo = lazy(()=>
  import("./AssistComponents/AssistProVideo")
);
class Assist extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t("header_link_assist");
    return (
      <div>
        <Helmet>
          {/* Browser */}
          <title>{t("assist_meta_title")}</title>
          <meta name="title" content={t("assist_meta_title")} />
          <meta name="description" content={t("assist_meta_description")} />

          {/* Google */}
          {/* <meta itemprop="name" content={t("assist_meta_title")} /> */}
          <meta itemprop="name" content="Accessible documents in Microsoft Office with Assist | Dania Software" />
          <meta itemprop="description" content={t("assist_meta_description")} />

          {/* Social media */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t("assist_meta_title")} />
          <meta
            property="og:description"
            content={t("assist_meta_description")}
          />
          <meta property="og:image" content={t("assist_meta_screenshot")} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t("assist_meta_title")} />
          <meta
            property="twitter:description"
            content={t("assist_meta_description")}
          />
          <meta
            property="twitter:image"
            content={t("assist_meta_screenshot")}
          />
        </Helmet>
        <Suspense
          fallback={
            <div
              style={{ display: "block", width: "100%", height: "2000px" }}
            ></div>
          }
        >
          <Hero t={t} />
          <LogoCloud t={t} />

          {/* Only show this section on the english page */}
          {t('header_link_assist') === "/assist-pro" ? (<Consultation t={t} />) : ""} 
          
          {/* <AssistProVideo t={t} /> */}
          <Feature t={t} />
          <AlternativeFeature t={t} />
          <FeatureList t={t} />
          <Suspense
            fallback={
              <div
                style={{ display: "block", width: "100%", height: "800px" }}
              ></div>
            }
          >
          <LatestAssistPosts t={t} />
          </Suspense>
          <FAQ t={t} />
          <CenteredCard t={t} />
        </Suspense>
      </div>
    );
  }
}
export default withTranslation()(Assist);
