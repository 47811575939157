import React from "react";
import Image from "@graphcms/react-image";

const GraphImage = (props) => {
  const image = {
    handle: props.handle,
    width: Math.floor(props.width),
    height: Math.floor(props.height)
  }
  const secondaryProps = {
    alt: props.alt,
    className: props.className,
    outerWrapperClassName: props.outerWrapperClassName,
    fit: props.fit,
    fadeIn: props.fadeIn ? (props.fadeIn.toLowerCase() === "true") : true,
    blur: props.blur ? (props.blur.toLowerCase() === "true") : true,
    blurryPlaceholder: props.blurryPlaceholder ? (props.blurryPlaceholder.toLowerCase() === "true") : true,
    withWebp: true,
    transforms: props.quality ? ['quality=value:' + props.quality] : ['quality=value:55']
  }
  
  return <Image blurryPlaceholder={secondaryProps.blurryPlaceholder} image={image} outerWrapperClassName={secondaryProps.outerWrapperClassName} className={secondaryProps.className} alt={secondaryProps.alt} fadeIn={secondaryProps.fadeIn} blur={secondaryProps.blur} maxWidth={1920} fit={secondaryProps.fit} transforms={secondaryProps.transforms} />
}

export default GraphImage;