import { React } from "../../../react";
import { Component, Suspense, lazy } from 'react';
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";

const Hero = lazy(() => import("./DirectComponents/Hero"));
const LogoCloud = lazy(() => import("./DirectComponents/LogoCloud"));
const AlternativeFeature = lazy(() => import("./DirectComponents/AlternativeFeature"));
const Testimonial = lazy(() => import("./DirectComponents/Testimonial"))
const FeatureList = lazy(() => import("./DirectComponents/FeatureList"));
const BrandPanel = lazy(() => import("./DirectComponents/BrandPanel"));

class Direct extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t('header_link_direct');
    return(
        <div>
          <Helmet>
          <title>{t('direct_meta_title')}</title>
          <meta name="title" content={t('direct_meta_title')} />
          <meta name="description" content={t('direct_meta_description')} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t('direct_meta_title')} />
          <meta property="og:description" content={t('direct_meta_description')} />
          <meta property="og:image" content={t('direct_meta_screenshot')} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t('direct_meta_title')} />
          <meta property="twitter:description" content={t('direct_meta_description')} />
          <meta property="twitter:image" content={t('direct_meta_screenshot')} />
        </Helmet>
        <Suspense fallback={<div style={{ display: "block", width: "100%", height: "2000px" }}></div>}>
          <Hero t={t} />
          <LogoCloud t={t} />
          <AlternativeFeature t={t} />
          <Testimonial t={t} />
          <FeatureList t={t} /> 
          <BrandPanel t={t} />
        </Suspense>
      </div>
    );
  }
}
export default withTranslation()(Direct);