import { React } from "../../react";
import { Component, Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

class CookiePolicy extends Component {
  componentDidMount() {
    window.legal.document("#cookie-policy-container", "oaUz5zgTphQ487wbtFJALRQC");
  }
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t("header_link_privacy_policy");
    return (
      <div>
        <Helmet>
          <title>{t("cookie_meta_title")}</title>
          <meta name="title" content={t("cookie_meta_title")} />
          <meta name="description" content={t("cookie_meta_description")} />

          {/* Google */}
          <meta itemprop="name" content={t("cookie_meta_title")} />
          <meta itemprop="description" content={t("cookie_meta_description")} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t("cookie_meta_title")} />
          <meta
            property="og:description"
            content={t("404_meta_description")}
          />
          <meta property="og:image" content={t("cookie_meta_screenshot")} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t("cookie_meta_title")} />
          <meta
            property="twitter:description"
            content={t("cookie_meta_description")}
          />
          <meta property="twitter:image" content={t("cookie_meta_screenshot")} />
        </Helmet>
        <article>
          <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                <svg
                  className="absolute top-12 left-full transform translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                </svg>
                <svg
                  className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
                <svg
                  className="absolute bottom-12 left-full transform translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                </svg>
              </div>
            </div>
            <div className="relative h-full text-lg mx-auto max-w-4xl w-full" aria-hidden="true">
              <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-4xl mx-auto">
                  <h1>
                    <span className="block text-base text-center text-dania-500 font-semibold tracking-wide uppercase">
                      {t('cookie_legal')}
                    </span>
                  </h1>
                  <div className="mt-8 prose-lg" id="cookie-policy-container" ></div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default withTranslation()(CookiePolicy);
