import { React } from '../react'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";


import Header from "./globals/Header";
import Footer from "./globals/footer/Footer";
import Home from "./subpages/Home";

import Newsroom from './subpages/blog/Newsroom';
import CaseStudies from './subpages/blog/CaseStudies';
import Events from './subpages/blog/Events';
import Article from "./content/Article";

import DynamicTemplate from "./subpages/solutions/DynamicTemplate";
import Assist from "./subpages/solutions/Assist"
import Connect365 from './subpages/solutions/Connect365';
import SignatureCloud from './subpages/solutions/SignatureCloud';
import Direct from './subpages/solutions/Direct';

import AssistProBusinessCase from "./subpages/CTAs/BusinessCase/AssistPro";

import About from "./subpages/company/About";
import Contact from "./subpages/company/Contact";
import Career from './subpages/company/Career';
import OpenPosition from "./content/OpenPosition";
import Customers from './subpages/company/Customers';
import Partners from './subpages/company/Partners';
import Support from './subpages/company/Support';

import BookDemo from './subpages/CTAs/BookDemo';
import BookDemoSC from './subpages/CTAs/BookDemoSignatureCloud';
import Pricing from './subpages/CTAs/Pricing';
import ThankYou from './subpages/CTAs/ThankYou';
import ThankYouVB from './subpages/CTAs/ThankYouVB';
import Consult from './subpages/CTAs/Consult';

import VisionBoard from './subpages/CTAs/VisionBoard';
import FramtidensDokumentproduktion from './subpages/CTAs/FramtidensDokumentproduktion';

import Integrations from './subpages/solutions/Integrations';
import Integration from './content/Integration';

import RuleEngine from './subpages/solutions/AddOns/RuleEngine';
import Insight from './subpages/solutions/AddOns/Insight';
import DesignTemplates from './subpages/solutions/AddOns/DesignTemplates';
import ELearning from './subpages/solutions/AddOns/ELearning';

import PrivacyPolicy from './subpages/PrivacyPolicy';
import CookiePolicy from './subpages/CookiePolicy';
import TermsOfService from './subpages/TermsOfService';
import PageNotFound from './subpages/404';

import CookieBot from 'react-cookiebot';

export default function App() {
  const domainGroupId = "b255f258-ed72-4e92-8049-766d9717d883"
  return (
    <Router>
      <Header />
      <main style={{ paddingTop: '90px' }} >
        <CookieBot domainGroupId={domainGroupId} />
        <Switch >
          {/* SOLUTIONS */}
          <Route exact path="/dynamictemplate" component={DynamicTemplate} />
          <Route exact path="/:lang/dynamictemplate" component={DynamicTemplate} />

          <Route exact path="/dynamictemplate/integrations" component={Integrations} />
          <Route exact path="/dk/dynamictemplate/integrationer" component={Integrations} />
          <Route exact path="/se/dynamictemplate/integrationer" component={Integrations} />
          <Route exact path="/no/dynamictemplate/integrasjoner" component={Integrations} />

          <Route exact path="/dynamicTemplate/integrations/:slug" component={Integration} />
          <Route exact path="/dk/dynamicTemplate/integrationer/:slug" component={Integration} />
          <Route exact path="/se/dynamicTemplate/integrationer/:slug" component={Integration} />
          <Route exact path="/no/dynamicTemplate/integrasjoner/:slug" component={Integration} />

          <Route exact path="/assist-pro" component={Assist} />
          <Route exact path="/:lang/assist-pro" component={Assist} />

          {/* Assist Pro Business Case sub-page */}
          <Route exact path="/assist-pro/business-case" component={AssistProBusinessCase} />
          <Route exact path="/:lang/assist-pro/business-case" component={AssistProBusinessCase} />

          <Route exact path="/connect-365" component={Connect365} />
          <Route exact path="/:lang/connect-365" component={Connect365} />

          <Route exact path="/signaturecloud" component={SignatureCloud} />
          <Route exact path="/:lang/signaturecloud" component={SignatureCloud} />
          <Redirect exact path="/outlooksignature" to="/signaturecloud" />
          <Redirect exact path="/:lang/outlooksignature" to="/:lang/signaturecloud" />

          <Route exact path="/direct" component={Direct} />
          <Route exact path="/:lang/direct" component={Direct} />

          {/* Blog and articles */}
          <Route exact path="/blog" component={Newsroom} />
          <Route exact path="/no/blogg" component={Newsroom} />
          <Route exact path="/se/blogg" component={Newsroom} />
          <Route exact path="/:lang/blog" component={Newsroom} />

          <Route exact path="/case-studies" component={CaseStudies} />
          <Route exact path="/dk/cases" component={CaseStudies} />
          <Route exact path="/no/caser" component={CaseStudies} />
          <Route exact path="/se/cases" component={CaseStudies} />
          <Route exact path="/:lang/case-studies" component={CaseStudies} />

          <Route exact path="/events" component={Events} />
          <Route exact path="/:lang/events" component={Events} />

          <Route exact path="/blog/:slug" component={Article} />
          <Route exact path="/no/blogg/:slug" component={Article} />
          <Route exact path="/se/blogg/:slug" component={Article} />
          <Route exact path="/:lang/blog/:slug" component={Article} />

          {/* Company pages */}
          <Route exact path="/about" component={About} />
          <Route exact path="/dk/om-os" component={About} />
          <Route exact path="/no/om-oss" component={About} />
          <Route exact path="/se/om-oss" component={About} />
          <Route exact path="/:lang/about" component={About} />

          <Route exact path="/contact" component={Contact} />
          <Route exact path="/dk/kontakt" component={Contact} />
          <Route exact path="/no/kontakt" component={Contact} />
          <Route exact path="/se/kontakt" component={Contact} />

          <Route exact path="/career" component={Career} />
          <Route exact path="/dk/karriere" component={Career} />
          <Route exact path="/no/karriere" component={Career} />
          <Route exact path="/se/karriär" component={Career} />

          <Route exact path="/open-positions/:slug" component={OpenPosition} />
          <Route exact path="/dk/åbne-stillinger/:slug" component={OpenPosition} />
          <Route exact path="/no/ledige-stillinger/:slug" component={OpenPosition} />
          <Route exact path="/se/lediga-tjänster/:slug" component={OpenPosition} />
          <Route exact path="/:lang/open-positions/:slug" component={OpenPosition} />

          <Route exact path="/customers" component={Customers} />
          <Route exact path="/dk/kunder" component={Customers} />
          <Route exact path="/no/kunder" component={Customers} />
          <Route exact path="/se/kunder" component={Customers} />

          <Route exact path="/partners" component={Partners} />
          <Route exact path="/dk/partnere" component={Partners} />
          <Route exact path="/no/partner" component={Partners} />
          <Route exact path="/se/partner" component={Partners} />

          <Route exact path="/support" component={Support} />
          <Route exact path="/:lang/support" component={Support} />

          {/* CTAs */}
          <Route exact path="/signaturecloud/book-demo" component={BookDemoSC} />
          <Route exact path="/:lang/signaturecloud/book-demo" component={BookDemoSC} />

          <Route exact path="/book-demo" component={BookDemo} />
          <Route exact path="/:lang/book-demo" component={BookDemo} />

          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/dk/pris" component={Pricing} />
          <Route exact path="/no/pris" component={Pricing} />
          <Route exact path="/se/pris" component={Pricing} />

          <Route exact path="/thank-you" component={ThankYou} />
          <Route exact path="/dk/tak" component={ThankYou} />
          <Route exact path="/no/takk" component={ThankYou} />
          <Route exact path="/se/tack" component={ThankYou} />
          <Route exact path="/:lang/thank-you" component={ThankYou} />

          <Route exact path="/dk/vb-tak" component={ThankYouVB} />

          <Route exact path="/consult" component={Consult} />

          {/* Add-on Pages */}
          <Route exact path="/dynamictemplate/insight" component={Insight} />
          <Route exact path="/:lang/dynamictemplate/insight" component={Insight} />

          <Route exact path="/dynamictemplate/design-templates" component={DesignTemplates} />
          <Route exact path="/dk/dynamictemplate/design-skabeloner" component={DesignTemplates} />
          <Route exact path="/no/dynamictemplate/design-maler" component={DesignTemplates} />
          <Route exact path="/se/dynamictemplate/design-mallar" component={DesignTemplates} />

          <Route exact path="/dynamictemplate/rule-engine" component={RuleEngine} />
          <Route exact path="/dk/dynamictemplate/regelmotor" component={RuleEngine} />
          <Route exact path="/no/dynamictemplate/regelmotor" component={RuleEngine} />
          <Route exact path="/se/dynamictemplate/regelmotor" component={RuleEngine} />

          <Route exact path="/dynamictemplate/e-learning" component={ELearning} />
          <Route exact path="/dk/dynamictemplate/e-laering" component={ELearning} />
          <Route exact path="/no/dynamictemplate/e-laering" component={ELearning} />
          <Route exact path="/se/dynamictemplate/e-utbildning" component={ELearning} />

          {/* HOME */}
          <Route exact path="/" component={Home} />
          <Route exact path="/dk/" component={Home} />
          <Route exact path="/se/" component={Home} />
          <Route exact path="/no/" component={Home} />

          {/* VisionBoard Invitational 2023 */}
          <Route exact path="/dk/visionboard2023" component={VisionBoard} />

          {/* Framtidens dokumentproduktion */}
          <Route exact path="/se/framtidens-dokumentproduktion" component={FramtidensDokumentproduktion} />

          {/* Privacy policy */}
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/dk/persondatapolitik" component={PrivacyPolicy} />
          <Route exact path="/no/personvernpolicy" component={PrivacyPolicy} />
          <Route exact path="/se/personuppgiftspolicy" component={PrivacyPolicy} />

          {/* Cookie Policy*/}
          <Route exact path="/cookiepolicy" component={CookiePolicy} />
          <Route exact path="/dk/cookie-politik" component={CookiePolicy} />
          <Route exact path="/:lang/cookiepolicy" component={CookiePolicy} />

          {/* Terms of service */}
          <Route exact path="/terms-of-service" component={TermsOfService} />
          <Route exact path="/:lang/terms-of-service" component={TermsOfService} />

          {/* Accessibility Whitepaper redirect*/}
          <Route exact path="/pdf/accessibility-whitepaper"
            render={() => {
              window.location.href = "https://media.graphassets.com/LkWUztEnQsKhLaRszecr?_gl=1*b7xqvd*_ga*MTUxMTUwMzI5OS4xNjQ1MDg3ODcx*_ga_G6FYGSYGZ4*MTY1Mjc4OTE1Ny4yMC4xLjE2NTI3ODk1NzUuNw..";
              return null;
            }}
          />


          {/* EULA redirect*/}
          <Route exact path="/eula"
            render={() => {
              window.location.href = "https://media.graphassets.com/tDYeoi0eQmKkYBmLhMh0?_gl=1*mut1ej*_ga*NzAwMDI3MjAyLjE2ODEyODA0OTQ.*_ga_G6FYGSYGZ4*MTY4MTI4MDUwNC4xLjEuMTY4MTI4MDU0Mi4yMi4wLjA.";
              return null;
            }}
          />
          <Route exact path="/:lang/eula"
            render={() => {
              window.location.href = "https://media.graphassets.com/tDYeoi0eQmKkYBmLhMh0?_gl=1*mut1ej*_ga*NzAwMDI3MjAyLjE2ODEyODA0OTQ.*_ga_G6FYGSYGZ4*MTY4MTI4MDUwNC4xLjEuMTY4MTI4MDU0Mi4yMi4wLjA.";
              return null;
            }}
          />

          {/* Assist redirect*/}
          <Route exact path="/assist"
            render={() => {
              window.location.href = "https://daniasoftware.com/assist-pro";
              return null;
            }}
          />
          <Route exact path="/:lang/assist"
            render={(e) => {
              var lang = e.match?.params?.lang
              if (lang !== null && lang !== "") {
                window.location.href = "https://daniasoftware.com/" + lang + "/assist-pro";
              }
              return null;
            }}
          />
          <Route exact path="/assistpro"
            render={() => {
              window.location.href = "https://daniasoftware.com/assist-pro";
              return null;
            }}
          />
          <Route exact path="/:lang/assistpro"
            render={(e) => {
              var lang = e.match?.params?.lang
              if (lang !== null && lang !== "") {
                window.location.href = "https://daniasoftware.com/" + lang + "/assist-pro";
              }
              return null;
            }}
          />

          {/* Connect365 redirect*/}
          <Route exact path="/connect365"
            render={() => {
              window.location.href = "https://daniasoftware.com/connect-365";
              return null;
            }}
          />
          <Route exact path="/:lang/connect365"
            render={(e) => {
              var lang = e.match?.params?.lang
              if (lang !== null && lang !== "") {
                window.location.href = "https://daniasoftware.com/" + lang + "/connect-365";
              }
              return null;
            }}
          />

          {/* Page not found */}
          <Route component={PageNotFound} />

          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </Router>
  )
}