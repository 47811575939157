import React, { Component, Suspense, lazy } from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';

const Hero = lazy(() => import("./IntegrationsComponents/Hero"));
const IntegrationList = lazy(() => import("./IntegrationsComponents/IntegrationList"));

class Integrations extends Component {
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t('integrations_link');
    return (
      <div>
        <Helmet>
          <title>{t('integrations_meta_title')}</title>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="title" content={t('integrations_meta_title')} />
          <meta name="description" content={t('integrations_meta_description')} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t('integrations_meta_title')} />
          <meta property="og:description" content={t('integrations_meta_description')} />
          <meta property="og:image" content={t('integrations_meta_screenshot')} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t('integrations_meta_title')} />
          <meta property="twitter:description" content={t('integrations_meta_description')} />
          <meta property="twitter:image" content={t('integrations_meta_screenshot')} />
        </Helmet>
        <Suspense fallback={<div style={{ display: "block", width: "100%", height: "2000px" }}></div>}>
          <Hero t={t} />
          <IntegrationList t={t} />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(Integrations);