import { React} from "../../../react";
import { Component, Suspense, lazy } from 'react';
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";

const Hero = lazy(() => import("./DynamicTemplateComponents/Hero"));
const Alternating = lazy(() => import("./DynamicTemplateComponents/AlternatingWithTestimonial"));
const GridOnBrand = lazy(() => import("./DynamicTemplateComponents/GridOnBrand"));
const Feature = lazy(() => import("./DynamicTemplateComponents/Feature"));
const GridExtraFeatures = lazy(() => import("./DynamicTemplateComponents/GridExtraFeatures")); 
const LatestDTPosts = lazy(() => import("./DynamicTemplateComponents/LatestDTPosts")); 
const BrandPanel = lazy(() => import("./DynamicTemplateComponents/BrandPanel"));

class DynamicTemplate extends Component {
  constructor(props){
    super(props);

  }
  render (){
    const { t } = this.props;
    const baseUrl = window.location.origin + t('header_link_dynamictemplate');
    
    return(
        <div>
          <Helmet>
          <title>{t('dt_meta_title')}</title>
          <meta name="title" content={t('dt_meta_title')} />
          <meta name="description" content={t('dt_meta_description')} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t('dt_meta_title')} />
          <meta property="og:description" content={t('dt_meta_description')} />
          <meta property="og:image" content={t('dt_meta_screenshot')} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t('dt_meta_title')} />
          <meta property="twitter:description" content={t('dt_meta_description')} />
          <meta property="twitter:image" content={t('dt_meta_screenshot')} />
        </Helmet>
            <Suspense fallback={<div style={{display: "block", width: "100%", height: "2000px"}}></div>}>
                <Hero t={t}/>
                <Alternating t={t}/>
                <GridOnBrand t={t}/>
                <Feature t={t}/>
                <GridExtraFeatures t={t} />
                <Suspense fallback={<div style={{display: "block", width: "100%", height: "800px"}}></div>}>
                    <LatestDTPosts t={t}/>
                </Suspense>
                <BrandPanel t={t}/>
            </Suspense>
        </div>
    );
  }
}
export default withTranslation()(DynamicTemplate);