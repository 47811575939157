import { React } from "../../../react";
import { Component, Suspense, lazy } from 'react';
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";

const Hero = lazy(() => import("./SignatureCloudComponents/Hero"));
const LogoCloud = lazy(() => import("./SignatureCloudComponents/LogoCloud"));
const AlternatingWithTestimonial = lazy(() => import("./SignatureCloudComponents/AlternatingWithTestimonial"));
const FeatureList = lazy(() => import("./SignatureCloudComponents/FeatureList"));
const CenteredCard = lazy(() => import("./SignatureCloudComponents/CenteredCard"));

class SignatureCloud extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t('header_link_signatureCloud');
    return(
        <div>
          <Helmet>
          <title>{t('sc_meta_title')}</title>
          <meta name="title" content={t('sc_meta_title')} />
          <meta name="description" content={t('sc_meta_description')} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t('sc_meta_title')} />
          <meta property="og:description" content={t('sc_meta_description')} />
          <meta property="og:image" content={t('sc_meta_screenshot')} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t('sc_meta_title')} />
          <meta property="twitter:description" content={t('sc_meta_description')} />
          <meta property="twitter:image" content={t('sc_meta_screenshot')} />
        </Helmet>
        <Suspense fallback={<div style={{ display: "block", width: "100%", height: "2000px" }}></div>}>
          <Hero t={t} />
          <LogoCloud t={t} />
          <AlternatingWithTestimonial t={t} />
          <FeatureList t={t} />
          <CenteredCard t={t} />
        </Suspense>
      </div>
    );
  }
}
export default withTranslation()(SignatureCloud);