import { React } from "../../react";
import { Component, Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Main = lazy(() => import("./PrivacyPolicyComponents/Main"));

class PageNotFound extends Component {
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t("header_link_privacy_policy");
    return (
      <div>
        <Helmet>
          <title>{t("privacy_policy_meta_title")}</title>
          <meta name="title" content={t("privacy_policy_meta_title")} />
          <meta name="description" content={t("privacy_policy_meta_description")} />

          {/* Google */}
          <meta itemprop="name" content={t("privacy_policy_meta_title")} />
          <meta itemprop="description" content={t("privacy_policy_meta_description")} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t("privacy_policy_meta_title")} />
          <meta
            property="og:description"
            content={t("404_meta_description")}
          />
          <meta property="og:image" content={t("privacy_policy_meta_screenshot")} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t("privacy_policy_meta_title")} />
          <meta
            property="twitter:description"
            content={t("404_meta_description")}
          />
          <meta property="twitter:image" content={t("privacy_policy_meta_screenshot")} />
        </Helmet>
        <Suspense
          fallback={
            <div
              style={{ display: "block", width: "100%", height: "2000px" }}
            ></div>
          }
        >
          <Main t={t} />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(PageNotFound);
