import { React } from '../../../react'
import { Component } from "react";
import { withTranslation } from 'react-i18next'
import i18next from '../../../index'
import PopUp from "../PopUp";
import ReCAPTCHA from "react-google-recaptcha";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      accepted: '',
      status: '',
      show: false,
      subscribeClicked: false,
      capVal: null,
      consent: false,
      formError: false,
      sitekey: "6Ld7X3gpAAAAAALCEGC03CboYgzZqO-5WGDeMjga"
    }
    this.handleChange = this.handleChange.bind(this);
    this.setCapVal = this.setCapVal.bind(this);
    this.handleSubscribeClicked = this.handleSubscribeClicked.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  }
  setCapVal(event) {
    this.setState({
      capVal: event
    })
  }
  setformError(val) {
    this.setState({
      formError: val
    })
  }
  handleSubscribeClicked() {
    this.setState({
      subscribeClicked: false
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    const { t } = this.props;
    const url = t('newsletter_subscribe_api') + `&email=${this.state.email}&recaptchaToken=${this.state.capVal}`;
    try {
      fetch(url).then((res) => res.json()).then(data => {
        if (data?.status === "User is already subscribed.") {
          this.setState({ show: true, status: "AlreadySubscribed", subscribeClicked: true })
        } else {
          this.setState({ show: true, status: "NewSubscriber", subscribeClicked: true })
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin;

    const navigation = {
      solutions: [
        { name: 'footer_solution_dynamictemplate', href: baseUrl + t('header_link_dynamictemplate') },
        { name: 'footer_solution_assist', href: baseUrl + t('header_link_assist') },
        { name: 'footer_solution_connect365', href: baseUrl + t('header_link_connect365') },
        { name: 'footer_solution_signaturecloud', href: baseUrl + t('header_link_signatureCloud') },
        { name: 'footer_solution_direct', href: baseUrl + t('header_link_direct') }
      ],
      company: [
        { name: 'footer_company_about', href: baseUrl + t('header_link_about') },
        { name: 'footer_company_career', href: baseUrl + t('header_link_career') },
        { name: 'footer_company_contact', href: baseUrl + t('header_link_contact') },
        { name: 'footer_company_customers', href: baseUrl + t('header_link_customers') },
        { name: 'footer_company_partners', href: baseUrl + t('header_link_partners') },
        { name: 'footer_company_support', href: baseUrl + t('header_link_support') }
      ],
      legal: [
        { name: 'footer_policies_cookie_policy', href: baseUrl + t('header_link_cookie_policy') },
        { name: 'footer_policies_privacy_policy', href: baseUrl + t('header_link_privacy_policy') },
        { name: 'footer_policies_tos', href: baseUrl + t('header_link_tos') },
      ],
      social: [
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/DaniaSoftware/',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
        {
          name: 'LinkedIn',
          href: 'https://www.linkedin.com/company/dania-software/',
          icon: (props) => (
            <svg fill="currentColor" style={{ height: "20px", marginTop: "2px" }} viewBox="0 0 24 24" {...props}>
              <path d="M20.45,20.45H16.89V14.88c0-1.33,0-3-1.85-3s-2.13,1.45-2.13,2.94v5.67H9.35V9h3.42v1.56h0a3.76,3.76,0,0,1,3.37-1.85c3.61,0,4.27,2.37,4.27,5.46ZM5.34,7.43h0A2.07,2.07,0,1,1,7.4,5.37h0A2.07,2.07,0,0,1,5.34,7.43m1.78,13H3.56V9H7.12ZM22.22,0H1.77A1.76,1.76,0,0,0,0,1.73V22.27A1.76,1.76,0,0,0,1.77,24H22.22A1.76,1.76,0,0,0,24,22.27V1.73A1.77,1.77,0,0,0,22.22,0" />
            </svg>
          ),
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/DaniaSoftware',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          ),
        },
      ],
    }
    return (
      <footer className="bg-white" aria-labelledby="footerHeading">
        {
          this.state.subscribeClicked && (
            <PopUp action={this.handleSubscribeClicked} subscribeClicked={this.state.subscribeClicked} status={this.state.status} t={t} />
          )
        }
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid small-phone:grid-cols-1 grid-cols-2 small-phone:gap-12 gap-24 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-32">
                <div style={{ minWidth: "200px" }}>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Dania Software A/S</h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <span className="text-base text-gray-500">
                        Strandvejen 111
                      </span>
                    </li>
                    <li>
                      <span className="text-base text-gray-500">
                        {t('footer_address')}
                      </span>
                    </li>
                    <li>
                      <span className="text-base text-gray-500">
                        +45 5850 3030
                      </span>
                    </li>
                    <li>
                      <a href="mailto:contact@daniasoftware.com" className="text-base text-dania-500 hover:text-dania-900">
                        {t('footer_description_email')}
                      </a>
                    </li>
                    <li>
                      <span className="text-base text-gray-500">
                        {t('footer_VAT')}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('footer_solutions')}</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {t(item.name)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('footer_company')}</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {t(item.name)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t('footer_legal')}</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {t(item.name)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-8 xl:mt-0">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                {t('footer_text_subscribe')}
              </h3>
              <p className="mt-4 text-base text-gray-500">
                {t('footer_text_latest_news')}
              </p>
              <form onSubmit={this.handleSubmit} className="mt-4 sm:max-w-md">
                <div className="sm:flex">
                  <label htmlFor="emailAddress" className="sr-only">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="emailAddress"
                    autoComplete="email"
                    required="required"
                    value={this.state.email}
                    onChange={this.handleChange}
                    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-dania-500 focus:border-indigo-500 focus:placeholder-gray-400"
                    placeholder={t('footer_enter_email')}
                  />
                  {this.state.capVal ?
                    <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        type="submit"
                        className="w-full bg-dania-500 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white hover:bg-dania-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {t('newsroom_btn_subscribe')}
                      </button>
                    </div> :
                    <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                      <button
                        type="submit"
                        disabled
                        className="cursor-not-allowed w-full bg-gray-500 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base font-medium text-white"
                      >
                        {t('newsroom_btn_subscribe')}
                      </button>
                    </div>
                  }
                </div>
                {this.state.sitekey && this.state.consent ?
                  <div className="sm:col-span-2 mt-8">
                    <ReCAPTCHA
                      sitekey={this.state.sitekey}
                      onChange={this.setCapVal} />
                  </div> : <div></div>

                }
                <div className="mt-3 rounded-md sm:mt-0 sm:flex-shrink-0">
                  <div style={{ marginTop: "20px" }} className="mb-16 h-5">
                    <input
                      style={{ float: "left", marginTop: "5px" }}
                      id="consent"
                      name="consent"
                      type="checkbox"
                      required="required"
                      value={this.state.consent}
                      onChange={this.handleChange}
                      className="focus:ring-dania-500 h-4 w-4 text-dania-600 border-gray-300 rounded cursor-pointer"
                    />
                    <label htmlFor="consent" style={{ float: "left", marginLeft: "10px", marginTop: "1px", width: "85%" }} className="cursor-pointer text-gray-500 pb-4">{t('footer_subscribe_consent')}</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; {t('footer_copyright')}
            </p>
          </div>
        </div>
      </footer>
    )
  }

}
export default withTranslation(i18next)(Footer);