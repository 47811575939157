import React, { Component } from "react";
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

class PopUp extends Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(subscribeClicked) {
        subscribeClicked = false;
    }
    render() {
        const { t } = this.props;
        const { status } = this.props;
        const { subscribeClicked } = this.props;

        const subscriber = (
            <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
                <div className="w-full flex flex-col mt-auto items-center space-y-4 sm:items-end">
                    <Transition
                        show={subscribeClicked}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {status === "NewSubscriber" ? (<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />) :
                                            (<ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />)}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        {status === "NewSubscriber" ? (<p className="text-sm font-medium text-gray-900">{t('subscribe_thank_you')}</p>) :
                                            (<p className="text-sm font-medium text-gray-900">{t('subscribe_whoops')}</p>)}

                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button
                                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
                                            onClick={this.props.action}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        )
        return (
            subscriber
        );
    }
}
export default PopUp;
