import React, { Component, Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const FeaturedCaseStudy = lazy(() =>
  import("./CaseStudiesComponents/FeaturedCaseStudy")
);
const CaseStudyCollection = lazy(() =>
  import("./CaseStudiesComponents/CaseStudyCollection")
);
const NewsletterSignup = lazy(() =>
  import("./CaseStudiesComponents/NewsletterSignup")
);

class CaseStudies extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t("header_link_case_studies");
    return (
      <div>
        <Helmet>
          <title>{t("case_studies_meta_title")}</title>
          <meta name="title" content={t("case_studies_meta_title")} />
          <meta name="description" content={t("case_studies_meta_description")} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t("case_studies_meta_title")} />
          <meta
            property="og:description"
            content={t("case_studies_meta_description")}
          />
          <meta property="og:image" content={t("case_studies_meta_screenshot")} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t("case_studies_meta_title")} />
          <meta
            property="twitter:description"
            content={t("case_studies_meta_description")}
          />
          <meta
            property="twitter:image"
            content={t("case_studies_meta_screenshot")}
          />
        </Helmet>
        <Suspense
          fallback={
            <div
              style={{ display: "block", width: "100%", height: "2000px" }}
            ></div>
          }
        >
          <FeaturedCaseStudy t={t} />
        </Suspense>
        <Suspense
          fallback={
            <div
              style={{ display: "block", width: "100%", height: "2000px" }}
            ></div>
          }
        >
          <CaseStudyCollection t={t} />
          <NewsletterSignup t={t} />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(CaseStudies);
