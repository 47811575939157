import React, { Component } from "react";

class OpenPositions extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.fetch();
  }
  fetch = async () => {
    const { t } = this.props;
    const url = t("career_open_positions_url");
    try {
      const openPositions = await fetch(url).then((res) => res.json());
      this.setState({
        OpenPositions: openPositions,
      });
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  getDateString(date) {
    let optionsMonth = { month: "short" };
    let optionsDate = { day: "numeric" };
    let optionsYear = { year: "numeric" };

    let dMonth = new Intl.DateTimeFormat("en-GB", optionsMonth).format(
      new Date(date)
    );
    let dDate = new Intl.DateTimeFormat("en-GB", optionsDate).format(
      new Date(date)
    );
    let dYear = new Intl.DateTimeFormat("en-GB", optionsYear).format(
      new Date(date)
    );

    return dMonth + " " + dDate + ", " + dYear;
  }
  render() {
    const { t } = this.props;

    const openPositions = this.state?.OpenPositions?.positions?.map(
      (position) => (
        <div key={position.title}>
          <div>
              <span className="inline-block text-gray-500 inline-flex items-center py-0.5 rounded-full text-sm font-medium">
                <time dateTime={position.date}>
                  {this.getDateString(position.date)}
                </time>
              </span>
          </div>
          <div className="block mt-4">
            <p className="text-xl font-semibold text-gray-900">
              {position.title}
            </p>
            <p className="mt-3 text-base text-gray-500 mb-2">
              {position.description}
            </p>
            <a
              className="text-dania-500 font-bold"
              href={"open-positions/" + position.slug}
            >
              {t("career_read_full")}
            </a>
          </div>
        </div>
      )
    );
    return (
      <div className="bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              {t("career_open_positions")}
            </h2>
          </div>
          <div className="grid gap-16 mt-2 small-tablet:grid-cols-2 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {openPositions}
            <div key={t("career_unsolicited_application")}>
              <p style={{marginTop: "2.6rem"}} className="text-xl font-semibold text-gray-900">
                {t("career_unsolicited_application")}
              </p>
              <p className="mt-3 text-base text-gray-500 mb-2">
                {t("career_unsolicited_application_description_1")}
                <a
                  className="text-dania-500"
                  href="mailto:job@daniasoftware.com"
                >
                  <span> job@daniasoftware.com </span>
                </a>
                <span className="whitespace-pre-line">
                  {t("career_unsolicited_application_description_2")}{" "}
                </span>
                <a className="text-black underline font-medium" href={t('header_link_privacy_policy')}>
                  <span>{t("career_privacy_policy")}</span>
                </a>
              </p>
              <a
                className="text-dania-500 font-bold"
                href="mailto:job@daniasoftware.com"
              >
                {t("career_send_unsolicited_application")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OpenPositions;
