import { React } from "../../react";
import { Component, Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Hero = lazy(() => import("./HomeComponents/Hero"));
const LogoCloud = lazy(() => import("./HomeComponents/LogoCloud"));
const Feature = lazy(() => import("./HomeComponents/Feature"));
const Testimonial = lazy(() => import("./HomeComponents/Testimonial"));
const FeatureGrid = lazy(() => import("./HomeComponents/FeatureGrid"));
const LatestFromBlog = lazy(() => import("./HomeComponents/LatestFromBlog"));
const BrandPanel = lazy(() => import("./HomeComponents/BrandPanel"));

class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t("header_link_root");
    return (
      <div>
        <Helmet>
          <title>{t("home_meta_title")}</title>
          <meta name="title" content={t("home_meta_title")} />
          <meta name="description" content={t("home_meta_description")} />

          {/* Google */}
          <meta itemprop="name" content={t("home_meta_title")} />
          <meta itemprop="description" content={t("home_meta_description")} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content={t("home_meta_title")} />
          <meta
            property="og:description"
            content={t("home_meta_description")}
          />
          <meta property="og:image" content={t("home_meta_screenshot")} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content={t("home_meta_title")} />
          <meta
            property="twitter:description"
            content={t("home_meta_description")}
          />
          <meta property="twitter:image" content={t("home_meta_screenshot")} />
        </Helmet>
        <Suspense
          fallback={
            <div
              style={{ display: "block", width: "100%", height: "2000px" }}
            ></div>
          }
        >
          <Hero t={t} />
          <LogoCloud t={t} />
          <Feature t={t} />
          <Testimonial t={t} />
          <FeatureGrid t={t} />
          <LatestFromBlog t={t} />
          <BrandPanel t={t} />
        </Suspense>
      </div>
    );
  }
}

export default withTranslation()(Home);
