
import { React, GraphImage } from '../../react'
import { Component, Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  EyeIcon,
  GlobeAltIcon,
  GlobeIcon,
  CloudIcon,
  MenuIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  ShieldCheckIcon,
  StarIcon,
  SupportIcon,
  TrendingUpIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/outline'
import i18next from '../../index'
import ReactCountryFlag from "react-country-flag"
import { withTranslation } from 'react-i18next'

const languages = [
  {
    name: "English",
    nameDK: "Engelsk",
    nameSE: "Engelska",
    nameNO: "Engelsk",
    countryCode: "GB",
    lang: "EN"
  },
  {
    name: "Danish",
    nameDK: "Dansk",
    nameSE: "Danska",
    nameNO: "Dansk",
    countryCode: "DK",
    lang: "DK"
  },
  {
    name: "Norwegian",
    nameDK: "Norsk",
    nameSE: "Norska",
    nameNO: "Norsk",
    countryCode: "NO",
    lang: "NO"
  },
  {
    name: "Swedish",
    nameDK: "Svensk",
    nameSE: "Svenska",
    nameNO: "Svensk",
    countryCode: "SE",
    lang: "SE"
  },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { i18nLoaded: false, currentLanguage: "en", windowSlugs: null }
  }
  getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
  }
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  UNSAFE_componentWillMount() {
    const url = window.location.href,
      originUrl = window.location.origin
    // Check if language already exists in string
    if (languages.some(function (l) { return url.indexOf(originUrl + "/" + l.lang.toLowerCase()) > -1 })) {
      // Language already exists in url
      this.setState({ currentLanguage: url.split(originUrl + "/")[1].split("/")[0] })
    } else {
      // Language is not already in url
      this.setState({ currentLanguage: "en" })
    }
  }
  componentDidMount() {
    this.fetch();
  }
  componentDidUpdate() {
  }
  fetch = async () => {
    const { t } = this.props;
    const url = t('header_recent_entries_api');
    try {
      const articles = await fetch(url).then((res) => res.json());
      this.setState({
        Articles: articles
      });

    } catch (err) {
      console.log(err);
    } finally {
      this.getUserLanguage(this.props.t)
    }
  }
  getSlug(lang, slugs) {
    // Get the specific slug for each language
    const enSlug = slugs.find((slug) => {
      return slug.locale === "en";
    })?.slug;
    const dkSlug = slugs.find((slug) => {
      return slug.locale === "da";
    })?.slug;
    const seSlug = slugs.find((slug) => {
      return slug.locale === "se";
    })?.slug;
    const noSlug = slugs.find((slug) => {
      return slug.locale === "no";
    })?.slug;

    // Check which language we are looking for and construct the URL based on that
    if (window.dataType === "Article") {
      if (lang.toLowerCase() === "en") {
        return window.location.origin + "/blog/" + enSlug
      }
      else if (lang.toLowerCase() === "dk") {
        if (dkSlug)
          return window.location.origin + "/dk/blog/" + dkSlug
        else
          return window.location.origin + "/dk/blog/" + enSlug
      }
      else if (lang.toLowerCase() === "se") {
        if (seSlug)
          return window.location.origin + "/se/blogg/" + seSlug
        else
          return window.location.origin + "/se/blogg/" + enSlug
      }
      else if (lang.toLowerCase() === "no") {
        if (noSlug)
          return window.location.origin + "/no/blogg/" + noSlug
        else
          return window.location.origin + "/no/blogg/" + enSlug
      }
    } else if (window.dataType === "Integration") {
      if (lang.toLowerCase() === "en") {
        return window.location.origin + "/dynamictemplate/integrations/" + enSlug
      }
      else if (lang.toLowerCase() === "dk") {
        if (dkSlug)
          return window.location.origin + "/dk/dynamictemplate/integrationer/" + dkSlug
        else
          return window.location.origin + "/dk/dynamictemplate/integrationer/" + enSlug
      }
      else if (lang.toLowerCase() === "se") {
        if (seSlug)
          return window.location.origin + "/se/dynamictemplate/integrationer/" + seSlug
        else
          return window.location.origin + "/se/dynamictemplate/integrationer/" + enSlug
      }
      else if (lang.toLowerCase() === "no") {
        if (noSlug)
          return window.location.origin + "/no/dynamictemplate/integrasjoner/" + noSlug
        else
          return window.location.origin + "/no/dynamictemplate/integrasjoner/" + enSlug
      }
    } else if (window.dataType === "Open-position") {
      if (lang.toLowerCase() === "en") {
        return window.location.origin + "/open-positions/" + enSlug
      }
      else if (lang.toLowerCase() === "dk") {
        if (dkSlug)
          return window.location.origin + "/dk/åbne-stillinger/" + dkSlug
        else
          return window.location.origin + "/dk/åbne-stillinger/" + enSlug
      }
      else if (lang.toLowerCase() === "se") {
        if (seSlug)
          return window.location.origin + "/se/lediga-tjänster/" + seSlug
        else
          return window.location.origin + "/se/lediga-tjänster/" + enSlug
      }
      else if (lang.toLowerCase() === "no") {
        if (noSlug)
          return window.location.origin + "/no/ledige-stillinger/" + noSlug
        else
          return window.location.origin + "/no/ledige-stillinger/" + enSlug
      }
    }
    return ""
  }
  getUrlWithNewLanguage(lang) {
    const url = window.location.href,
      originUrl = window.location.origin

    lang = lang.toLowerCase()

    var newUrl = "";

    // Check if language already exists in string
    if (languages.some(function (l) { return url.indexOf(originUrl + "/" + l.lang.toLowerCase()) > -1 })) {
      // Language already exists in url

      // Get language from url
      const originalLang = url.split(originUrl + "/")[1].split("/")[0];

      // Construct url
      const endOfUrl = url.split(originUrl + "/" + originalLang)[1];
      newUrl = originUrl + (lang === 'en' ? "" : "/") + (lang === 'en' ? "" : lang) + endOfUrl;

    } else {
      // Language is not already in url
      // Construct url
      const endOfUrl = url.split(originUrl)[1];
      newUrl = originUrl + (lang === 'en' ? "" : "/") + (lang === 'en' ? "" : lang) + endOfUrl;
    }
    return newUrl
  }
  getUrlWithNewLanguageV2(lang, t) {
    if (!window.slugs) {
      // Get the list of routes in the current language
      const routes = i18next.getResourceBundle(i18next.language, 'routes');
      const currentPathname = decodeURI(window.location.pathname.replace(/\/+$/, ''));

      // Find the key for the url
      const currentRouteKey = routes ? Object.keys(routes)?.find((key) => routes[key] === currentPathname) : "";
      const newRouteKey = t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() });

      // If the key is found, return the corresponding url. If the key is an empty string, it must be the home page.
      if (newRouteKey !== "undefined")
        if (newRouteKey === "")
          return window.location.origin
        else
          return t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() })
      else
        return window.location.href
    } else if (window.slugs) {
      // If window.slugs exists, we must be on an article, so get the articles of other languages
      return this.getSlug(lang, window.slugs)
    }
  }
  getUrlWithNewLanguageRedirect(lang, t) {
    if (!window.slugs) {
      // Get the list of routes in the current language
      const routes = i18next.getResourceBundle(i18next.language, 'routes');
      const currentPathname = decodeURI(window.location.pathname.replace(/\/+$/, ''));

      // Find the key for the url
      const currentRouteKey = routes ? Object.keys(routes)?.find((key) => routes[key] === currentPathname) : "";
      const newRouteKey = t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() });

      // If the key is found, return the corresponding url. If the key is an empty string, it must be the home page.
      if (newRouteKey !== "undefined")
        if (newRouteKey === "")
          return window.location.origin
        else
          return t(`routes:${currentRouteKey}`, { lng: lang.toLowerCase() })
      else
        return window.location.href
    } else if (window.slugs) {
      // If window.slugs exists, we must be on an article, so get the articles of other languages
      return this.getSlug(lang, window.slugs)
    }
  }
  getUserLanguage(t) {
    // Browser language for redirecting to the correct language
    let location = navigator.language || navigator.userLanguage,
      cookie = this.getCookie("preferred_language"),
      redirectUrl = "",
      originUrl = window.location.origin,
      href = window.location.href
      
    if (href.charAt(href.length - 1) === "/")
      href = href.slice(0, -1)
  
    href = decodeURI(href)
    // Only do redirects if the user doesn't have query parameters in the url
    if (href.indexOf("?") < 0){
      // Check if cookie exists with preferred country, and redirect if it doesn't
      if (cookie === "" || cookie === null) {
    
        if (location !== null && location !== "") {
          switch (location) {
            case 'da':
              redirectUrl = this.getUrlWithNewLanguageRedirect("DK", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
                window.location.href = redirectUrl
              }
              break;
            case 'da-DK':
              redirectUrl = this.getUrlWithNewLanguageRedirect("DK", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
                window.location.href = redirectUrl
              }
              break;
            case 'da-GL':
              redirectUrl = this.getUrlWithNewLanguageRedirect("DK", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
                window.location.href = redirectUrl
              }
              break;
            case 'fo':
              redirectUrl = this.getUrlWithNewLanguageRedirect("DK", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
                window.location.href = redirectUrl
              }
              break;
            case 'fo-DK':
              redirectUrl = this.getUrlWithNewLanguageRedirect("DK", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
                window.location.href = redirectUrl
              }
              break;
            case 'fo-FO':
              redirectUrl = this.getUrlWithNewLanguageRedirect("DK", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl) {
                window.location.href = redirectUrl
              }
              break;
            case 'sv':
              redirectUrl = this.getUrlWithNewLanguageRedirect("SE", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            case 'se':
              redirectUrl = this.getUrlWithNewLanguageRedirect("SE", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            case 'se-SE':
              redirectUrl = this.getUrlWithNewLanguageRedirect("SE", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            case 'nb':
              redirectUrl = this.getUrlWithNewLanguageRedirect("NO", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            case 'no':
              redirectUrl = this.getUrlWithNewLanguageRedirect("NO", t)
              redirectUrl = decodeURI(redirectUrl)
    
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            case 'nn':
              redirectUrl = this.getUrlWithNewLanguageRedirect("NO", t)
              redirectUrl = decodeURI(redirectUrl)
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            case 'nb-NO':
              redirectUrl = this.getUrlWithNewLanguageRedirect("NO", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            case 'nn-NO':
              redirectUrl = this.getUrlWithNewLanguageRedirect("NO", t)
              redirectUrl = decodeURI(redirectUrl)
              
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
              break;
            default:
              redirectUrl = this.getUrlWithNewLanguageRedirect("EN", t)
              redirectUrl = decodeURI(redirectUrl)
    
              if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
                window.location.href = redirectUrl
          }
        }
      }else{
        redirectUrl = this.getUrlWithNewLanguageRedirect(cookie, t)

        if (href !== (originUrl + redirectUrl) && href !== redirectUrl)
          window.location.href = redirectUrl
      }
    }
  }
  render() {

    const { t } = this.props;
    const baseUrl = window.location.origin;
    const features = [
      {
        name: 'header_solution_dynamictemplate',
        href: baseUrl + t('header_link_dynamictemplate'),
        description: 'header_solution_dynamictemplate_description',
        icon: DocumentTextIcon,
      },
      {
        name: 'header_solution_assist',
        href: baseUrl + t('header_link_assist'),
        description: 'header_solution_assist_description',
        icon: EyeIcon,
      },
      {
        name: 'header_solution_connect365',
        href: baseUrl + t('header_link_connect365'),
        description: "header_solution_connect365_description",
        icon: ShieldCheckIcon
      },
      {
        name: 'header_solution_signatureCloud',
        href: baseUrl + t('header_link_signatureCloud'),
        description: "header_solution_signatureCloud_description",
        icon: CloudIcon,
      },
      {
        name: 'header_solution_direct',
        href: baseUrl + t('header_link_direct'),
        description: 'header_solution_direct_description',
        icon: PhoneIcon,
      },
    ]
    const callsToAction = [
      { name: 'header_btn_Live_demo', href: baseUrl + t('header_link_bookdemo'), icon: CalendarIcon },
      { name: 'header_btn_request_price', href: baseUrl + t('header_link_pricing'), icon: CurrencyDollarIcon },
    ]
    const blog = [
      {
        name: 'header_blog_newsroom',
        description: 'header_blog_newsroom_description',
        href: baseUrl + t('header_link_blog'),
        icon: NewspaperIcon,
      },
      {
        name: 'header_blog_case_studies',
        description: 'header_blog_case_studies_description',
        href: baseUrl + t('header_link_case_studies'),
        icon: StarIcon,
      },
      {
        name: 'header_blog_events',
        description: 'header_blog_events_description',
        href: baseUrl + t('header_link_events'),
        icon: CalendarIcon,
      }
    ]
    const company = [
      {
        name: 'header_company_about',
        href: baseUrl + t('header_link_about'),
        description: 'header_company_about_description',
        icon: OfficeBuildingIcon,
      },
      {
        name: 'header_company_career',
        href: baseUrl + t('header_link_career'),
        description: 'header_company_career_description',
        icon: TrendingUpIcon,
      },
      {
        name: 'header_company_contact',
        href: baseUrl + t('header_link_contact'),
        description: "header_company_contact_description",
        icon: PhoneIcon
      },
      {
        name: 'header_company_customers',
        href: baseUrl + t('header_link_customers'),
        description: "header_company_customers_description",
        icon: UserGroupIcon,
      },
      {
        name: 'header_company_partners',
        href: baseUrl + t('header_link_partners'),
        description: 'header_company_partners_description',
        icon: GlobeAltIcon,
      },
      {
        name: 'header_company_support',
        href: baseUrl + t('header_link_support'),
        description: 'header_company_support_description',
        icon: SupportIcon
      }
    ]
    const articles = this.state?.Articles?.articles.map((article) => (
      <li key={article.title} className="text-base truncate">
        <a href={window.location.origin + (this.state.currentLanguage.toLowerCase() === 'en' ? "" : "/" + this.state.currentLanguage.toLowerCase()) + "/blog/" + article.slug} className="font-medium text-gray-900 hover:text-gray-700">
          {article.title}
        </a>
      </li>
    ));
    return (
      <Popover style={{ position: 'fixed', width: '100%', top: '0', zIndex: '999' }} className="relative bg-white shadow dania-header">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center py-6 md-header:justify-start md-header:space-x-10 dania-header">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href={baseUrl + t('header_link_root')}>
                    <GraphImage outerWrapperClassName="w-48 small-tablet-header:hidden"
                      className="dania-image"
                      alt="Dania Software Logo"
                      handle="KcNxzSvSvCHth7Uyma8b" quality="85" width="700" height="150" fit="scale" blurryPlaceholder="false" fadeIn="false" blur="false" />
                    <GraphImage outerWrapperClassName="w-12 smaller-device-header:hidden larger-device:hidden" alt="Dania Software Logo" quality="85" handle="GmUlqkj0QGeLerEzmkW6" width="128" height="128" fit="scale" blurryPlaceholder="false" fadeIn="false" blur="false" />
                  </a>
                </div>
                <div className="-mr-2 -my-2 flex md-header:hidden">
                  <Popover.Group className="md-header:hidden">
                    <Popover as="nav" className="md-header:flex">
                      {({ open }) => (
                        <>
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dania-500">
                            <span className="sr-only">Choose language</span>
                            <GlobeIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel
                              static
                              className="absolute left-1/4 z-10 transform w-3/4 large-phone:w-1/2 large-phone:left-1/2 px-2 sm:px-0"
                            >
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                <div className="pt-5 pb-6 px-5">
                                  <div className="overflow-hidden">
                                    <div className="relative grid gap-6 bg-white px-6 py-6 sm:gap-8 sm:p-8">
                                      {languages.map((item) => (
                                        item.lang.toLowerCase() === this.state.currentLanguage.toLowerCase()
                                          ?
                                          <span
                                            key={item.name}
                                            onClick={() => this.setCookie("preferred_language", item.lang, 100)}
                                            href={this.getUrlWithNewLanguageV2(item.lang, t)}
                                            className="-m-3 p-3 flex items-start rounded-lg bg-gray-50"
                                          >
                                            <ReactCountryFlag
                                              countryCode={item.countryCode}
                                              svg
                                              style={{ width: '1.5em', height: '1.5em', float: 'left' }}
                                            />
                                            <p className="ml-4 text-base font-medium text-gray-900">{item['name' + this.state.currentLanguage.toUpperCase()] ? item['name' + this.state.currentLanguage.toUpperCase()] : item.name}</p>
                                          </span>
                                          :
                                          <a
                                            key={item.name}
                                            onClick={() => this.setCookie("preferred_language", item.lang, 100)}
                                            href={this.getUrlWithNewLanguageV2(item.lang, t)}
                                            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                          >
                                            <ReactCountryFlag
                                              countryCode={item.countryCode}
                                              svg
                                              style={{ width: '1.5em', height: '1.5em', float: 'left' }}
                                            />
                                            <p className="ml-4 text-base font-medium text-gray-900">{item['name' + this.state.currentLanguage.toUpperCase()] ? item['name' + this.state.currentLanguage.toUpperCase()] : item.name}</p>
                                          </a>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                  <Popover.Group className="md-header:hidden">
                    <Popover as="nav" className=" md-header:flex">
                      {({ open }) => (
                        <>
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dania-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <Popover.Panel
                              static
                              className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md-header:hidden"
                            >
                              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                <div className="pt-5 pb-6 px-5">
                                  <div className="flex items-center justify-between">
                                    <GraphImage outerWrapperClassName="w-1/2 sm:w-4/12" alt="Dania Software Logo" handle="KcNxzSvSvCHth7Uyma8b" width="233" height="50" fit="scale" blurryPlaceholder="false" fadeIn="false" blur="false" />
                                    <div className="-mr-2">
                                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dania-500">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                      </Popover.Button>
                                    </div>
                                  </div>
                                  <div className="mt-6">
                                    <nav className="grid gap-y-8">
                                      {features.map((item) => (
                                        <a
                                          key={item.name}
                                          href={item.href}
                                          className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >
                                          <item.icon className="flex-shrink-0 h-6 w-6 text-dania-500" aria-hidden="true" />
                                          <span className="ml-3 text-base font-medium text-gray-900">{t(item.name)} {(t(item.extension) ? <span style={{ marginTop: "0.2rem" }} className="ml-1 px-1 rounded-md box-border text-sm font-medium absolute text-dania-500 bg-dania-100"> {t(item.extension)}</span> : "")}</span>
                                        </a>
                                      ))}
                                    </nav>
                                  </div>
                                </div>
                                <div className="py-6 px-5 space-y-6">
                                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                    {blog.map((item) => (
                                      <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                                      >
                                        {t(item.name)}
                                      </a>
                                    ))}
                                    {company.map((item) => (
                                      <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                                      >
                                        {t(item.name)}
                                      </a>
                                    ))}
                                  </div>
                                  <div>
                                    <a
                                      href={baseUrl + t('header_link_bookdemo')}
                                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-dania-500 hover:bg-dania-700"
                                    >
                                      {t('header_btn_Live_demo')}
                                    </a>
                                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                                      <a target="_blank" href="https://apps.daniasoftware.com" className="text-dania-600 hover:text-dania-500">
                                        {t('header_btn_Sign_in')}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                </div>
                <Popover.Group as="nav" className="hidden md-header:flex space-x-10">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-500',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500'
                          )}
                        >
                          <span>{t('header_dd_Solutions')}</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-gray-400',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute left-1/2 z-10 transform -translate-x-1/2 mt-3 px-2 w-48 sm:px-0 md-header:hidden"
                          >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                              <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                  <div>
                                    <img
                                      className="h-8 w-auto"
                                      src="https://media.graphassets.com/output=format:png/ThXZJNIwT1mboifCGYkS"
                                      alt="Workflow"
                                    />
                                  </div>
                                  <div className="-mr-2">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dania-500">
                                      <span className="sr-only">Close menu</span>
                                      <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                  </div>
                                </div>
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                  <div className="relative grid gap-6 bg-white px-6 py-6 sm:gap-8 sm:p-8">
                                    {languages.map((item) => (
                                      item.lang.toLowerCase() === this.state.currentLanguage.toLowerCase()
                                        ?
                                        <span
                                          key={item.name}
                                          href={this.getUrlWithNewLanguage(item.lang)}
                                          onClick={() => this.setCookie("preferred_language", item.lang, 100)}
                                          className="-m-3 p-3 flex items-start rounded-lg bg-gray-50"
                                        >
                                          <ReactCountryFlag
                                            countryCode={item.countryCode}
                                            svg
                                            style={{ width: '1.5em', height: '1.5em', float: 'left' }}
                                          />
                                          <p className="ml-4 text-base font-medium text-gray-900">{item['name' + this.state.currentLanguage.toUpperCase()] ? item['name' + this.state.currentLanguage.toUpperCase()] : item.name}</p>
                                        </span>
                                        :
                                        <a
                                          key={item.name}
                                          href={this.getUrlWithNewLanguage(item.lang)}
                                          onClick={() => this.setCookie("preferred_language", item.lang, 100)}
                                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                        >
                                          <ReactCountryFlag
                                            countryCode={item.countryCode}
                                            svg
                                            style={{ width: '1.5em', height: '1.5em', float: 'left' }}
                                          />
                                          <p className="ml-4 text-base font-medium text-gray-900">{item['name' + this.state.currentLanguage.toUpperCase()] ? item['name' + this.state.currentLanguage.toUpperCase()] : item.name}</p>
                                        </a>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                          >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {features.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                  >
                                    <item.icon className="flex-shrink-0 h-6 w-6 text-dania-500" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">{t(item.name)}
                                        {(item.extension ? <span style={{ marginTop: "0.2rem" }} className="ml-1 px-1 rounded-md box-border text-sm font-medium absolute text-dania-500 bg-dania-100"> {t(item.extension)}</span> : "")}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">{t(item.description)}</p>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                {callsToAction.map((item) => (
                                  <div key={item.name} className="flow-root">
                                    <a
                                      href={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                    >
                                      <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                      <span className="ml-3">{t(item.name)}</span>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-500',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500'
                          )}
                        >
                          <span>{t('header_dd_Blog')}</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-gray-400',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute left-1/2 z-10 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                          >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {blog.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                  >
                                    <item.icon className="flex-shrink-0 h-6 w-6 text-dania-500" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">{t(item.name)}</p>
                                      <p className="mt-1 text-sm text-gray-500">{t(item.description)}</p>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              <div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                                <div>
                                  <h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
                                    {t('header_blog_recent_entries')}
                                  </h3>
                                  <ul className="mt-4 space-y-4">
                                    {articles}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-500',
                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500'
                          )}
                        >
                          <span>{t('header_dd_Company')}</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-gray-600' : 'text-gray-400',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                          >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {company.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                  >
                                    <item.icon className="flex-shrink-0 h-6 w-6 text-dania-500" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">{t(item.name)}</p>
                                      <p className="mt-1 text-sm text-gray-500">{t(item.description)}</p>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                {callsToAction.map((item) => (
                                  <div key={item.name} className="flow-root">
                                    <a
                                      href={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                    >
                                      <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                      <span className="ml-3">{t(item.name)}</span>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </Popover.Group>
                <div className="hidden md-header:flex items-center justify-end md-header:flex-1 lg:w-0">
                  <div style={{ marginRight: "20px", verticalAlign: "middle", marginTop: "5px" }}>
                    <Popover.Group>
                      <Popover className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open ? 'text-gray-900' : 'text-gray-500',
                                'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500'
                              )}
                            >
                              <GlobeIcon className="flex-shrink-0 h-5 w-5 text-gray-500" aria-hidden="true" />
                              <ChevronDownIcon
                                className={classNames(
                                  open ? 'text-gray-600' : 'text-gray-400',
                                  'ml-2 h-5 w-5 group-hover:text-gray-500'
                                )}
                                aria-hidden="true"
                              />
                            </Popover.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel
                                static
                                className="absolute left-1/2 z-10 transform -translate-x-1/2 mt-3 px-2 w-48 sm:px-0"
                              >
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                  <div className="relative grid gap-6 bg-white px-6 py-6 sm:gap-8 sm:p-8">
                                    {languages.map((item) => (
                                      item.lang.toLowerCase() === this.state.currentLanguage.toLowerCase()
                                        ?
                                        <span
                                          key={item.name}
                                          href={this.getUrlWithNewLanguageV2(item.lang, t)}
                                          onClick={() => this.setCookie("preferred_language", item.lang, 100)}
                                          className="-m-3 p-3 flex items-start rounded-lg bg-gray-50"
                                        >
                                          <ReactCountryFlag
                                            countryCode={item.countryCode}
                                            svg
                                            style={{ width: '1.5em', height: '1.5em', float: 'left' }}
                                          />
                                          <p className="ml-4 text-base font-medium text-gray-900">{item['name' + this.state.currentLanguage.toUpperCase()] ? item['name' + this.state.currentLanguage.toUpperCase()] : item.name}</p>
                                        </span>
                                        :
                                        <a
                                          key={item.name}
                                          href={this.getUrlWithNewLanguageV2(item.lang, t)}
                                          onClick={() => this.setCookie("preferred_language", item.lang, 100)}
                                          className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                        >
                                          <ReactCountryFlag
                                            countryCode={item.countryCode}
                                            svg
                                            style={{ width: '1.5em', height: '1.5em', float: 'left' }}
                                          />
                                          <p className="ml-4 text-base font-medium text-gray-900">{item['name' + this.state.currentLanguage.toUpperCase()] ? item['name' + this.state.currentLanguage.toUpperCase()] : item.name}</p>
                                        </a>
                                    ))}
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    </Popover.Group>
                  </div>
                  <a target="_blank" href="https://apps.daniasoftware.com" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                    {t('header_btn_Sign_in')}
                  </a>
                  <a
                    href={baseUrl + t('header_link_bookdemo')}
                    className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-dania-500 hover:bg-dania-700"
                  >
                    {t('header_btn_Live_demo')}
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </Popover>
    )
  }
}
export default withTranslation(i18next)(Header);