import React, { Component, Suspense, lazy } from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';

const Contact = lazy(() => import("./FramtidensDokumentproduktion/Contact"));
const LogoCloud = lazy(() => import("./Common/LogoCloud"));

class FramtidensDokumentproduktion extends Component {
  render() {
    const { t } = this.props;
    const baseUrl = window.location.origin + t('header_link_framtidens_dokumentproduktion');
    return (
      <div>
        <Helmet>
          <title>Framtidens dokumentproduktion 2023</title>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="title" content="Framtidens dokumentproduktion" />
          <meta name="description" content="Framtidens dokumentproduktion" />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseUrl} />
          <meta property="og:title" content="Framtidens dokumentproduktion" />
          <meta property="og:description" content="Framtidens dokumentproduktion"/>
          <meta property="og:image" content="" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={baseUrl} />
          <meta property="twitter:title" content="Framtidens dokumentproduktion" />
          <meta property="twitter:description" content="Framtidens dokumentproduktion" />
          <meta property="twitter:image" content="" />
        </Helmet>
        <Suspense fallback={<div style={{ display: "block", width: "100%", height: "2000px" }}></div>}>
          <Contact t={t} />
          <LogoCloud t={t} />
        </Suspense>
      </div> 
    );
  }
}

export default withTranslation()(FramtidensDokumentproduktion);